<template>
    <v-app id="inspire">
        <v-theme-provider root>
            <v-main>
                <router-view></router-view>
            </v-main>
        </v-theme-provider>

        <v-fab-transition>
            <v-btn
                v-scroll="onScroll"
                v-show="fab"
                fab
                color="white"
                fixed
                width="50"
                height="50"
                bottom
                right
                @click="toTop"
                class="scroll-fab"
            >
                <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polyline points="18 15 12 9 6 15"></polyline>
                </svg>
            </v-btn>
        </v-fab-transition>
        <loader
            v-if="$store.state.loading == true"
            object="#242424"
            color1="#ffffff"
            color2="#242424"
            size="5"
            speed="1"
            bg="#343a40"
            objectbg="#999793"
            opacity="80"
            name="circular"
        ></loader>
        <v-dialog v-model="pagar" width="500" persistent>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Deuda pendiente
                </v-card-title>

                <v-card-text class="mt-4 text-center text-body-1">
                    Favor Pagar a ABC Software
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import moment from "moment";
export default {
    data: () => ({
        fab: false,
        pagar: false,
    }),

    created() {
        this.FetchPage();
      
    },
    mounted() {
        // setInterval(() => this.refreshHead(), 1000);
        // this.refreshHead();
    },
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 60;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        CheckIfPay() {
            var days_deadline = 60;
            let diff = moment(this.$store.state.iglesia.Fecha_Vencimiento).diff(
                this.$store.state.iglesia.Fecha_Pago,
                "days"
            );

            if (diff <= 0 && this.$store.state.iglesia.Fecha_Pago) {
                console.log(diff);
                this.pagar = true;
                // document.body.style.opacity ="0%";
                // document.body.style. = "<div>Hola</div>"
            }
        },

        FetchPage() {
            this.$store
                .dispatch("get_Iglesia", this.$route.params.Nombre_Id)
                .then((res) => {
                    this.CheckIfPay();

                    if (this.$store.state.TransID != "") {
                        this.$store.state.loading = true;
                        this.$store.dispatch("SaveCredit").then((res) => {
                            console.log(res);
                            if (res == 1) {
                                this.$store.state.loading = false;
                                this.$store.commit("clear_TransID");
                            }
                        });
                    }

                    this.$store.dispatch("get_Last_Predica");
                });
        },
    },
};
</script>

<style lang="scss">
.v-btn--fab.v-btn--contained {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
    height: 30px;
    font-size: 30px;
    width: 30px;
}
.v-btn--fab.v-btn--fixed {
    z-index: 99;
}
.v-btn--absolute.v-btn--bottom,
.v-btn--fixed.v-btn--bottom {
    bottom: 60px;
}

.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
    right: 20px;
}
.scroll-fab {
    &.white {
        border: none;
    }
    svg {
        font-size: 24px;
    }
}
</style>
