import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=720ae8f0&"
import script from "./MainLayout.vue?vue&type=script&lang=js&"
export * from "./MainLayout.vue?vue&type=script&lang=js&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VMain } from 'vuetify/lib/components/VMain';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';
installComponents(component, {VApp,VBtn,VCard,VCardText,VCardTitle,VDialog,VFabTransition,VMain,VThemeProvider})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
